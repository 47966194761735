<template>
  <b-modal
    id="program-modal"
    ref="program-modal"
    size="lg"
    v-model="isVisible"
    :title="this.title"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
    @hide="close()"
  >
    <messages />

    <b-row>
      <b-col>
        <h5><strong>Data Change</strong></h5>
        <div
          v-for="dataChangeDetail in dataChangeDetails"
          :key="dataChangeDetail.changeRequestFieldID"
        >
          <p>{{ dataChangeDetail.fieldName }} : {{ dataChangeDetail.newValue }}</p>
          <hr />
        </div>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col>
        <b-form-group
          :label="Fieldlabel == true ? 'Applied Reason' : 'Deny Reason'"
          class="has-float-label mb-2"
        >
          <b-form-input type="text" v-model="company.appliedDeniedReason" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-3">
        <div class="loginbtnbg">
          <api-button-overlay :processing="processing">
            <b-button class="login-blue" @click="SubmitAppliedDenied">
              <span class="label">Submit</span>
            </b-button>
          </api-button-overlay>
        </div>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div></div>
    </template>
  </b-modal>
</template>
<script>
import CompanyMixin from '../../mixins/CompanyMixin.vue'
import { mapMutations } from 'vuex'
// import Multiselect from "vue-multiselect";
import ModalMessages from '../../containers/navs/ModalMessages.vue'
// import { mutations } from "../../store/Login";

export default {
  data () {
    return {
      isVisible: false,
      company: {
        appliedDeniedReason: '',
        changeRequestID: 0
      },
      companyID: 0,
      title: undefined,
      Fieldlabel: true
    }
  },
  mixins: [CompanyMixin],
  components: {
    // multiselect: Multiselect,
    messages: ModalMessages
  },

  methods: {
    ...mapMutations(['setModalInfoMessage', 'setModalDangerMessage']),
    async show (opts = {}) {
      this.title = opts.title
      this.company.changeRequestID = parseInt(opts.changeRequestID)
      this.Fieldlabel = opts.Fieldlabel
      await this.getChangeRequestByID()
      this.isVisible = true
    },
    close () {
      this.clearData()
      // this.$router.push({ name: "Dashboard" });
    },
    clearData () {
      this.company = {
        appliedDeniedReason: ''
      }
    },
    async SubmitAppliedDenied () {
      // var { companyID = "" } = JSON.parse(localStorage.getItem("currentCompany"));
      if (this.Fieldlabel === true) {
        console.log('apply')
        await this.updateApplyReason(this.company.changeRequestID, this.company)
      } else {
        console.log('Deny')
        await this.updateDenyReason(this.company.changeRequestID, this.company)
      }

      this.isVisible = false
      this.setPreserveMessageAfterPush(true)
      this.close()
    }
  }
}
</script>
