<template>
<div>
    <ApplyDenyModal ref="companyAddressModal"></ApplyDenyModal>
    <Confirmation ref="Confirmation"></Confirmation>
      <datatable-heading
        :hasTitleSection="false"
        :changePageSize="changePageSize"
        :searchChange="_.debounce(searchChange, 500)"
        :from="from"
        :to="to"
        :total="total"
        :perPage="perPage"
        :hasPageSizes="false"
        :titleAndButton="true">
        <template v-slot:button>
          <div class="title-and-right-element">
            <PageTitle :title="'Change Request'" :identifier="'Company #'+ companyID"></PageTitle>
          </div>
        </template>
      </datatable-heading>
      <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
        <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="companyAddressID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded">
              <template slot="appliedDenied" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <div>
                     <b-button variant="outline-success" size="xs" @click="Apply" v-if="props.rowData.appliedDenied == null" :data-changeRequestId="props.rowData.changeRequestID">Apply</b-button>
                     <b-button class="btn-adjustment" variant="outline-danger" size="xs"  @click="Deny()" v-if="props.rowData.appliedDenied == null">Deny</b-button>
                    <p class="text-muted" v-if="props.rowData.appliedDenied == 1">
                     Applied
                    </p>
                     <p class="text-muted" v-if="props.rowData.appliedDenied == 0">
                     Denied
                    </p>
                  </div>
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination ref="pagination"
            class="mt-4"
            @vuetable-pagination:change-page="onChangePage"/>
        </b-colxx>
      </b-row>
      </b-overlay>

  </div>
</template>
<style scoped>
  label {
    color: #676461;
    font-weight: bold;
  }
</style>
<script>
import { BeaconapiUrl } from '../../../constants/config'
import Confirmation from '../../../components/Common/Confirmation.vue'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CompanyMixin from '../../../mixins/CompanyMixin.vue'
import { validationMixin } from 'vuelidate'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'
import ApplyDenyModal from '../../../components/Modals/ApplyDenyModal.vue'
import { mapMutations, mapActions } from 'vuex'
import moment from 'moment/moment.js'

const {
  required,
  minLength,
  helpers
} = require('vuelidate/lib/validators')

export default {
  props: ['companyID'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: Confirmation,
    PageTitle: ParentEntityPageTitle,
    ApplyDenyModal: ApplyDenyModal
  },
  mixins: [validationMixin, CompanyMixin],
  data () {
    var _idfield = 'companyAddressID'
    return {
      // apiBase: `${BeaconapiUrl}/api/ChangeRequest/table/133160`,
      sort: '',
      page: 1,
      perPage: 10,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      companyAddressID: 0,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right',
          width: '5%'
        },
        {
          name: 'changeRequestID',
          title: 'ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '30%'
        },
        {
          name: 'dateCreated',
          sortField: 'dateCreated',
          title: 'Date Created',
          titleClass: '',
          dataClass: 'text-muted',
          callback: this.formatDate,
          width: '15%'
        },
        {
          name: 'changeRequestDesc',
          sortField: 'changeRequestDesc',
          title: 'Change RequestDesc',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'appliedDeniedDate',
          sortField: 'appliedDeniedDate',
          title: 'Applied DeniedDate',
          titleClass: '',
          dataClass: 'text-muted',
          callback: this.formatDate,
          width: '10%'
        },
        {
          name: 'appliedDenied',
          sortField: 'appliedDenied',
          title: 'Applied Denied',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'changeRequestDesc',
          sortField: 'changeRequestDesc',
          title: 'Change RequestDesc',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: '__slot:appliedDenied',
          title: 'Actions',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        }
      ]
    }
  },
  mounted () {
    const urlParams = new URL(location.href).searchParams
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    Apply (event) {
      console.log('data', event.currentTarget.dataset)
      const { changeRequestId } = event.currentTarget.dataset
      this.$refs.ApplyDenyModal.show({
        title: 'Apply',
        Fieldlabel: true,
        companyID: this.companyID,
        changeRequestID: changeRequestId,
        exitFunction: () => {
          // this.refreshVueTable();
        }
      })
    },
    Deny () {
      this.$refs.ApplyDenyModal.show({
        title: 'Deny',
        Fieldlabel: false,
        companyID: this.companyID,
        exitFunction: () => {
          // this.refreshVueTable();
        }
      })
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM-DD-YYYY')
      }
    },
    onRowClass (dataItem, index) {},
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.$refs.vuetable.selectedTo = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.$refs.vuetable.refresh()
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    rowClicked (dataItem, event) {
      // check to see if we are clicking on the readMore/readLess link, if so do not select or unselect the row.
      if (event.path[0].id.indexOf('readMore') === 0 || event.path[0].id.indexOf('readLess') === 0) {
        const itemId = dataItem[this.$data.idfield]

        if (this.$refs.vuetable.selectedTo.includes(itemId)) {
          this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
            (x) => x !== itemId
          )
        } else {
          this.$refs.vuetable.selectedTo.push(itemId)
        }
        this.selectedItems = this.$refs.vuetable.selectedTo
      }
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    // delete one or multiple items
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    cellclicked (dataitem, field, event) {},
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    searchChange (val) {
      this.search = val
      this.$refs.vuetable.refresh()
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    }
  },
  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    }
  }
}
</script>
<style scoped>
.btn-adjustment{
margin-top: 5px
}

</style>
